import {
  PARTICIPANT_TYPES,
  PREPARED_AUTHORITIES,
  USER_ROLES,
} from '../../constants';
import { intersection } from 'lodash';

export const ADMINS = [
  PARTICIPANT_TYPES.OGV,
  PARTICIPANT_TYPES.CEM,
  PARTICIPANT_TYPES.IS_MP_OPERATOR,
]; // это участники-админы

export const ADMIN_USER_AUTHORITIES = [
  PREPARED_AUTHORITIES[USER_ROLES.ADMIN],
  PREPARED_AUTHORITIES[USER_ROLES.SUZ],
]; // это пользователи-админы

export const CAN_SEE_REPORTS = [
  ...ADMINS,
  PARTICIPANT_TYPES.PRODUCER,
  PARTICIPANT_TYPES.IMPORTER,
].filter(item => item !== PARTICIPANT_TYPES.CEM);

export const CAN_SEE_PRODUCTS = [
  PARTICIPANT_TYPES.PRODUCER,
  PARTICIPANT_TYPES.IMPORTER,
  PARTICIPANT_TYPES.IS_MP_OPERATOR,
  PARTICIPANT_TYPES.OGV,
];

export const CAN_SEE_HOMEPAGE = [
  PARTICIPANT_TYPES.IMPORTER,
  PARTICIPANT_TYPES.IS_MP_OPERATOR,
  PARTICIPANT_TYPES.OGV,
  PARTICIPANT_TYPES.PRODUCER,
  PARTICIPANT_TYPES.ROLE_HEAD_OGV,
  PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR,
  PARTICIPANT_TYPES.ROLE_ORG_OGV,
];

export const CAN_SEE_MOBILE = [
  PARTICIPANT_TYPES.IS_MP_OPERATOR,
  PARTICIPANT_TYPES.CONTENT_MANAGER,
];

export const CAN_SEE_PARTICIPANTS = [
  PARTICIPANT_TYPES.IS_MP_OPERATOR,
  PARTICIPANT_TYPES.OGV,
];

export const CAN_SEE_GRAY_AREA = [
  PARTICIPANT_TYPES.PRODUCER,
];

export const CAN_SEE_UNLOAD_SERVICE = [
  PARTICIPANT_TYPES.RETAIL,
  PARTICIPANT_TYPES.PRODUCER,
  PARTICIPANT_TYPES.IMPORTER,
  PARTICIPANT_TYPES.WHOLESALER,
  PARTICIPANT_TYPES.IS_MP_OPERATOR,
];

export const CAN_SEE_DOCUMENTS = [
  PARTICIPANT_TYPES.IS_MP_OPERATOR,
  PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR,
  PARTICIPANT_TYPES.ROLE_ORG_OGV,
  PARTICIPANT_TYPES.TRADE_PARTICIPANT,
]


export const canSeeMobile = (roles) => {
  return (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR) && roles.includes(PARTICIPANT_TYPES.CONTENT_MANAGER))
}

export const canSeeParticipants = (roles) => {
   return (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR) && (roles.includes(PARTICIPANT_TYPES.ROLE_SUPPORT) || roles.includes(PARTICIPANT_TYPES.ROLE_ADMIN)))
    || roles.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV);
}

export const canSeeReports = (roles) => {
  return (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR) && roles.includes(PARTICIPANT_TYPES.ROLE_REPORTS))
    || roles.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV)
    || (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_TRADE_PARTICIPANT)
    && !roles.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV)
    && !roles.includes(PARTICIPANT_TYPES.ROLE_HEAD_OGV)
    && !roles.includes(PARTICIPANT_TYPES.ROLE_HEAD_OPERATOR)
    && !roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR));
}

export const canSeeNewReports = (roles) => {
  return (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR) && roles.includes(PARTICIPANT_TYPES.ROLE_REPORTS))
    || roles.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV)
    || (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_TRADE_PARTICIPANT)
      && !roles.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV)
      && !roles.includes(PARTICIPANT_TYPES.ROLE_HEAD_OGV)
      && !roles.includes(PARTICIPANT_TYPES.ROLE_HEAD_OPERATOR)
      && !roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR));
}

export const canSeeAppeals = (roles) => {
  return (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR) && roles.includes(PARTICIPANT_TYPES.ROLE_SUPPORT))
    || roles.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV);
}

export const canSeeByReportOrOgv = (roles) => {
  return (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR) && roles.includes(PARTICIPANT_TYPES.ROLE_REPORTS))
    || roles.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV);
}

export const canSeeDocuments = (roles) => {
  return (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR) && roles.includes(PARTICIPANT_TYPES.ROLE_SUPPORT))
    || (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_TRADE_PARTICIPANT)
    && !roles.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV)
    && !roles.includes(PARTICIPANT_TYPES.ROLE_HEAD_OGV)
    && !roles.includes(PARTICIPANT_TYPES.ROLE_HEAD_OPERATOR)
    && !roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR));
}

export const canSeeCodes = (roles) => {
  return (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR) && roles.includes(PARTICIPANT_TYPES.ROLE_SUPPORT))
    || roles.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV)
    || (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_TRADE_PARTICIPANT)
      && !roles.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV)
      && !roles.includes(PARTICIPANT_TYPES.ROLE_HEAD_OGV)
      && !roles.includes(PARTICIPANT_TYPES.ROLE_HEAD_OPERATOR)
      && !roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR));
}
export const canSeeProducts = (roles) => {
  return (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR) && roles.includes(PARTICIPANT_TYPES.ROLE_SUPPORT))
    || roles.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV)
    || roles.includes(PARTICIPANT_TYPES.ROLE_HEAD_OGV);
}

export const canSeeRMT = (roles) => {
  return (
    roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR)
      && (
        roles.includes(PARTICIPANT_TYPES.ROLE_ADMIN)
        || roles.includes(PARTICIPANT_TYPES.ROLE_RMT_MODERATOR)
        || roles.includes(PARTICIPANT_TYPES.ROLE_RMT_SUPERMODERATOR)
        || roles.includes(PARTICIPANT_TYPES.ROLE_USER)
      )
    )
    ||
    (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_TRADE_PARTICIPANT)
      && (roles.includes(PARTICIPANT_TYPES.ROLE_RMT_PUBLISHER)
        || roles.includes(PARTICIPANT_TYPES.ROLE_RMT_USER)
        || roles.includes(PARTICIPANT_TYPES.ROLE_USER)
        || roles.includes(PARTICIPANT_TYPES.ROLE_ADMIN))
      && (!roles.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV)
        && !roles.includes(PARTICIPANT_TYPES.ROLE_HEAD_OGV)
        && !roles.includes(PARTICIPANT_TYPES.ROLE_HEAD_OPERATOR)
        && !roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR)
      )
    )
}

export const canSeeSUN = (roles) => {
  return (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR) && roles.includes(PARTICIPANT_TYPES.ROLE_SUPPORT))
    || (roles.includes(PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR) && roles.includes(PARTICIPANT_TYPES.ROLE_ADMIN))
    || roles.includes(PARTICIPANT_TYPES.ROLE_ORG_OGV);
}
